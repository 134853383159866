<template>
  <div>
    <!-- HANDELBAY INNOVATIVE -->
    <section id="buyers">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-7 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay lo mejor de dos mundos">
                {{ $t('lang.dual.the_best_of_two_worlds') }}
              </h2>
              <h3 class="title-2" itemprop="HandelBay en un solo lugar">
                {{ $t('lang.dual.in_one_single_place') }}
              </h3>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <p itemprop="HandelBay es la solución">
                {{ $t('lang.dual.HandelBay_is_a_business_shopping_platform') }}
              </p>
            </div>
            <br>
            <div class="title-footer">
              <a href="#" class="btn no-margin-left btn-handelbay-success btn-handelbay-black" data-toggle="modal" data-target="#modal-demo">
                {{ $t('lang.dual.schedule_your_demo_now') }}
              </a>
            </div>
          </div>
          <div class="col-md-5 col-sm-5">
            <div class="computers">
              <img src="../assets/images/landing/handelbay/handelbay_gestionintegralempresa.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </section>
    <!-- END HANDELBAY INNOVATIVE -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva" class="handelbay-background-curva-buyers">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Ahorros en las compras -->
    <section id="savings_in_purchases" class="savings_in_purchases">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-5 col-md-offset-1 col-sm-offset-0 col-xs-6 col-xs-offset-3 ">
          <div class="img_man">
            <img src="../assets/images/landing/handelbay/handelbay_gestionintegralempresa.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div>
        <div class="col-md-5 col-sm-7 text-right col-xs-12">
          <div class="handelbay-text" style="padding-bottom:18px;" itemscope itemtype="https://handelbay.com/">
            <h2 class="title">
              <b>
                {{ $t('lang.dual.comprehensive_management_of_your_company') }}
              </b>
            </h2>
            <p class="text">
              {{ $t('lang.dual.with_HandelBay_you_will_be_able_to_realize_everything_that_happens_in_the_commercial_area') }}
            </p>
            <p class="text">
              {{ $t('lang.dual.you_will_appear_in_HandelBay_as_dual_before_all_companies') }}
            </p>
            <h3 class="title space-title">
              <b>
                {{ $t('lang.dual.multiple_users_and_roles') }}
              </b>
            </h3>
            <p class="text">
              {{ $t('lang.dual.manage_your_commercial_advisors_separately') }}
            </p>
            <p class="text">
              {{ $t('lang.dual.you_have_at_your_disposal_profiles_captain_lieutenant_and_officer') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- End ahorros en las compras -->
    <!-- Red de expansión -->
    <section id="expansion_network" class="dual_network">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-md-offset-1 text-left">
          <h2 class="title">
            <b>
              {{ $t('lang.dual.security_in_negotiations') }}
            </b>
          </h2>
          <p class="text" itemprop="HandelBay es la solución">
            {{ $t('lang.dual.at_HandelBay_we_want_you_to_be_able_to_do_business_safely') }}
          </p>
          <p class="text">
            {{ $t('lang.dual.in_addition_at_HandelBay_we_require_all_companies_to_update_the_documentation_periodically') }}
          </p>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-6 col-xs-offset-3 col-sm-offset-0">
          <div class="brand">
            <img src="../assets/images/landing/handelbay/handelbay_img_center.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div>
        <div class="col-md-4 col-sm-4 text-right col-xs-12">
          <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
            <h3 class="title">
              <b>
                {{ $t('lang.dual.compare_quotes_and_providers') }}
              </b>
            </h3>
            <p class="text" itemprop="HandelBay red empresarial">
              {{ $t('lang.dual.can_you_imagine_being_able_to_make_a_comparative_table_of_suppliers_and_their_prices?') }}
            </p>
            <p class="text">
              {{ $t('lang.dual.how_would_it_be_to_know_the_price_history_of_all_the_purchases_you_make?') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- End Red de expansión -->
    <!-- Compara cotizaciones y proveedores -->
    <section id="compare_quotes">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-7 col-md-offset-1">
          <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
            <h2 class="title">
              <b>
                {{ $t('lang.dual.complete_portfolio_online_24_7') }}
              </b>
            </h2>
            <p class="text" itemprop="HandelBay es la solución">
              {{ $t('lang.dual.we_want_you_to_do_more_business_and_make_your_portfolio_known_to_everyone') }}
              <br>
              <b class="info_title">
                {{ $t('lang.dual.HandelBay_is_what_you_were_waiting_for_to_grow_your_business') }}
              </b>
            </p>
            <h3 class="title space-title">
              <b>
                {{ $t('lang.dual.you_have_50%_discount') }}
              </b>
            </h3>
            <p class="text">
              {{ $t('lang.dual.dont_hesitate_anymore_at_HandelBay_we_have_no_restrictions_for_your_combination_of_plans') }}
            </p>
            <p class="text">            
              <b class="info_title">
                {{ $t('lang.dual.plus_we_give_you_more') }}
              </b>
              <br>
              <b class="info_title">
                {{ $t('lang.dual.first_you_choose_a_buyer_plan_then_you_choose_a_provider_plan') }}
              </b>
              <b class="info_title">
                {{ $t('lang.dual.and_we_give_you_a_50%_discount_on_the_cheapest_plan') }}
              </b>
              <b class="info_title">
                {{ $t('lang.dual.of_the_two_We_want_you_to_be_the_best') }}
              </b>
            </p>          
          </div>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-6 col-xs-offset-3 col-sm-offset-0">
          <div class="brand">
            <img src="../assets/images/landing/handelbay/handelbay_portafoliocompletoenlinea.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- End Compara cotizaciones y proveedores -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Listo para innovar -->
    <section id="ready_to_innovate">
    <div class="container">
      <div class="title-footer text-center">
        <h2 class="title">
          {{ $t('lang.dual.are_you_ready_to_innovate_in_your_shopping_and_commercial_area?') }}
        </h2>
        <router-link to="/tarifas" class="btn btn-handelbay-dual">
          {{ $t('lang.dual.see_buyer_plans') }}
        </router-link>
        <a href="<%=tarifas_path%>" ></a>
      </div>
    </div>
    </section>
    <!-- End Listo para innovar -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Optimiza tus compras -->
    <section id="compare_quotes">
    <div class="container">   
      <div class="row">
        <div class="col-md-5 col-md-offset-1 col-sm-9 col-xs-6 col-xs-offset-3 col-sm-offset-0 text-left">
          <div class="brand">
            <img src="../assets/images/landing/handelbay/handelbay_unmundosinbeneficios.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div> 
        <div class="col-md-5 col-sm-5 col-xs-12 text-left">
          <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
            <h2 class="title">
              <b>
                {{ $t('lang.dual.a_world_of_benefits') }}
              </b>
            </h2>
            <p class="text" itemprop="HandelBay es la solución">
              {{ $t('lang.dual.if_you_are_a_buyer_and_a_seller_get_better_conditions') }}
            </p>
            <h3 class="title space-title">
              <b>
                {{ $t('lang.dual.integral_management') }}
              </b>
            </h3>
            <p class="text">
              {{ $t('lang.dual.obtain_traceability_in_all_your_purchasing_and_sales_processes_in_one_place') }}
            </p>
            <h4 class="title space-title">
              <b>
                {{ $t('lang.dual.real_time_reports') }}
              </b>
            </h4>
            <p class="text">
              {{ $t('lang.dual.control_your_budget_and_manage_the_results_of_your_sales_team_in_a_single_tool') }}
            </p>        
            <h5 class="title space-title">
              <b>
                {{ $t('lang.dual.surf_the_net') }}
              </b>
            </h5>  
            <p class="text">
              {{ $t('lang.dual.the_business_social_network_will_allow_you_to_expand_your_universe_of_suppliers') }}
            </p>  
          </div>
        </div>           
      </div>
      <!-- .row -->
      <br><br>
      <div class="row">
        <div class="col-md-10 col-sm-12 col-md-offset-1">        
          <div class="title-footer text-center">
            <a href="#" class="btn btn-handelbay-dual" data-toggle="modal" data-target="#modal-demo">
              {{ $t('lang.dual.schedule_your_demo') }}
            </a>
            <a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
            {{ $t('lang.dual.sign_up_for_free_now') }}
            </a>
          </div>
      </div>
      </div>
    </div>
    <!-- .container -->
    </section>
    <!-- End Optimiza tus compras -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->

    <PorqueHandelbay />
    <ModalTuDemo />
    <ModalPlans />
  </div>
</template>
<script>
import global from '@/components/Global.vue'
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"
import ModalPlans from "@/components/ModalPlans.vue"

export default {
  components:{
    PorqueHandelbay,
    ModalTuDemo,
    ModalPlans
  },
  data(){
    return{
      global: global
    }
  }
}
</script>
